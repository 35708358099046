define("forms/utils/check-drag", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = CheckDrag;
  function CheckDrag(element, checkboxElements) {
    this.checkState = !this.getState(element);
    this.changedCheckboxes = [element];
    this.checkboxes = checkboxElements;
    this.attachEvents(element, this.checkboxes);
  }
  CheckDrag.prototype.attachEvents = function (element, checkboxes) {
    this._dragEndHandler = this.dragEnd.bind(this, element);
    element.addEventListener('mouseup', this._dragEndHandler);
    document.body.addEventListener('mouseup', this._dragEndHandler);
    checkboxes.forEach(item => {
      item._cd_mouseEnterHandler = this.mouseEnter.bind(this, item);
      item.addEventListener('mouseenter', item._cd_mouseEnterHandler);
    });
  };
  CheckDrag.prototype.removehEvents = function (element, checkboxes) {
    element.removeEventListener('mouseup', this._dragEndHandler);
    document.body.removeEventListener('mouseup', this._dragEndHandler);
    checkboxes.forEach(item => {
      item.removeEventListener('mouseenter', item._cd_mouseEnterHandler);
      delete item._cd_mouseEnterHandler;
    });
  };
  CheckDrag.prototype.dragEnd = function (element) {
    this.removehEvents(element, this.checkboxes);
    if (this.changedCheckboxes.length <= 1) return;
    this.changedCheckboxes.forEach(this.triggerChange.bind(this));
  };
  CheckDrag.prototype.triggerChange = function (item) {
    const input = this.getInput(item);
    const event = document.createEvent('HTMLEvents');
    event.initEvent('change', true, false);
    input.dispatchEvent(event);
    // $(input).trigger('change'); // vanilla JS does not work here, dont know why :(
    // input.dispatchEvent(new Event('change'));
  };
  CheckDrag.prototype.getInput = function (element) {
    return element.children[0];
  };
  CheckDrag.prototype.getState = function (item) {
    const input = this.getInput(item);
    return input.checked;
  };
  CheckDrag.prototype.setState = function (item, state) {
    const input = this.getInput(item);
    input.checked = state;
  };
  CheckDrag.prototype.mouseEnter = function (element) {
    if (this.checkState !== null) {
      // Dragging from 1st -> 2nd checkbox initiates drag-check
      // In which case we should retroactively check the 1st box
      if (this.changedCheckboxes.length === 1) {
        this.setState(this.changedCheckboxes[0], this.checkState);
      }
      if (this.getState(element) !== this.checkState) {
        this.changedCheckboxes.push(element);
        this.setState(element, this.checkState);
      }
    }
  };
});
define("forms/helpers/can-select-time", ["exports", "@ember/component/helper", "@manager-web/common/utils/time"], function (_exports, _helper, _time) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.canSelectTime = canSelectTime;
  _exports.default = void 0;
  function canSelectTime([time, intervals, isIntervalEnd, duration] /*, hash*/) {
    if (!Array.isArray(intervals)) {
      return true;
    }
    time = (0, _time.timeToMinutes)(time);
    for (let i = 0; i < intervals.length; i++) {
      const interval = intervals[i];
      const a = (0, _time.timeToMinutes)(interval[0]);
      const b = (0, _time.timeToMinutes)(interval[1]);
      if (isIntervalEnd) {
        if (time > a && time <= b) {
          return true;
        }
      } else if (time >= a && time < b) {
        if (duration && time + duration > b) return false;
        return true;
      }
    }
    return false;
  }
  var _default = _exports.default = (0, _helper.helper)(canSelectTime);
});
define("@manager-web/grids/components/grid/card/content", ["exports", "@ember/component", "@ember/component/template-only", "@ember/template-factory"], function (_exports, _component, _templateOnly, _templateFactory) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  const __COLOCATED_TEMPLATE__ = (0, _templateFactory.createTemplateFactory)(
  /*
    <div
    class={{class-join
      "border-x border-gray-200 bg-white p-4"
      (unless (has-block "footer") "rounded-b-xl border-b")
      (unless (has-block "header") "rounded-t-xl border-t")
    }}
  >
    {{yield}}
  </div>
  */
  {
    "id": "768VpuSH",
    "block": "[[[10,0],[15,0,[28,[37,1],[\"border-x border-gray-200 bg-white p-4\",[52,[51,[48,[30,1]]],\"rounded-b-xl border-b\"],[52,[51,[48,[30,2]]],\"rounded-t-xl border-t\"]],null]],[12],[1,\"\\n  \"],[18,3,null],[1,\"\\n\"],[13]],[\"&footer\",\"&header\",\"&default\"],false,[\"div\",\"class-join\",\"unless\",\"has-block\",\"yield\"]]",
    "moduleName": "@manager-web/grids/components/grid/card/content.hbs",
    "isStrictMode": false
  });
  var _default = _exports.default = (0, _component.setComponentTemplate)(__COLOCATED_TEMPLATE__, (0, _templateOnly.default)());
});
define("@manager-web/grids/components/grid/card/list/item", ["exports", "@ember/component", "@ember/component/template-only", "@ember/template-factory"], function (_exports, _component, _templateOnly, _templateFactory) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  const __COLOCATED_TEMPLATE__ = (0, _templateFactory.createTemplateFactory)(
  /*
    <div
    class={{class-join
      "flex w-full items-center gap-4 p-6 last:rounded-b-xl"
      (if @zebra "odd:bg-gray-50")
      (if @isDisabled "opacity-50")
      (if @isClickable "hover:bg-yellow-100" "hover:bg-gray-100")
    }}
    ...attributes
    role={{if @isClickable "button"}}
  >
    {{yield}}
  </div>
  */
  {
    "id": "eOnoqoLw",
    "block": "[[[11,0],[16,0,[28,[37,1],[\"flex w-full items-center gap-4 p-6 last:rounded-b-xl\",[52,[30,1],\"odd:bg-gray-50\"],[52,[30,2],\"opacity-50\"],[52,[30,3],\"hover:bg-yellow-100\",\"hover:bg-gray-100\"]],null]],[17,4],[16,\"role\",[52,[30,3],\"button\"]],[12],[1,\"\\n  \"],[18,5,null],[1,\"\\n\"],[13]],[\"@zebra\",\"@isDisabled\",\"@isClickable\",\"&attrs\",\"&default\"],false,[\"div\",\"class-join\",\"if\",\"yield\"]]",
    "moduleName": "@manager-web/grids/components/grid/card/list/item.hbs",
    "isStrictMode": false
  });
  var _default = _exports.default = (0, _component.setComponentTemplate)(__COLOCATED_TEMPLATE__, (0, _templateOnly.default)());
});
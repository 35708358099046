define("@manager-web/grids/components/grid/container-full", ["exports", "@ember/component", "@ember/component/template-only", "@ember/template-factory"], function (_exports, _component, _templateOnly, _templateFactory) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  const __COLOCATED_TEMPLATE__ = (0, _templateFactory.createTemplateFactory)(
  /*
    <div
    ...attributes
    class={{class-join
      "flex flex-col gap-4 p-4 last:grow md:gap-8 md:p-8"
      (or @theme "bg-gray-100")
    }}
  >
    {{yield}}
  </div>
  */
  {
    "id": "eFEQqOQt",
    "block": "[[[11,0],[17,1],[16,0,[28,[37,1],[\"flex flex-col gap-4 p-4 last:grow md:gap-8 md:p-8\",[28,[37,2],[[30,2],\"bg-gray-100\"],null]],null]],[12],[1,\"\\n  \"],[18,3,null],[1,\"\\n\"],[13]],[\"&attrs\",\"@theme\",\"&default\"],false,[\"div\",\"class-join\",\"or\",\"yield\"]]",
    "moduleName": "@manager-web/grids/components/grid/container-full.hbs",
    "isStrictMode": false
  });
  var _default = _exports.default = (0, _component.setComponentTemplate)(__COLOCATED_TEMPLATE__, (0, _templateOnly.default)());
});
define("forms/components/filters-multiselect/option", ["exports", "@ember/component", "@ember/component/template-only", "@ember/template-factory"], function (_exports, _component, _templateOnly, _templateFactory) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  const __COLOCATED_TEMPLATE__ = (0, _templateFactory.createTemplateFactory)(
  /*
    <li class={{bem "filters-popover__option" selected=@isSelected}}>
    <FormCheckbox
      @isChecked={{@isSelected}}
      @rtl={{true}}
      @onChange={{@onClick}}
      @group={{@group}}
      @disabled={{@disabled}}
      data-filter-value={{@filterValue}}
    >
      {{yield}}
    </FormCheckbox>
  </li>
  */
  {
    "id": "yV3nIQyg",
    "block": "[[[10,\"li\"],[15,0,[28,[37,1],[\"filters-popover__option\"],[[\"selected\"],[[30,1]]]]],[12],[1,\"\\n  \"],[8,[39,2],[[16,\"data-filter-value\",[30,2]]],[[\"@isChecked\",\"@rtl\",\"@onChange\",\"@group\",\"@disabled\"],[[30,1],true,[30,3],[30,4],[30,5]]],[[\"default\"],[[[[1,\"\\n    \"],[18,6,null],[1,\"\\n  \"]],[]]]]],[1,\"\\n\"],[13]],[\"@isSelected\",\"@filterValue\",\"@onClick\",\"@group\",\"@disabled\",\"&default\"],false,[\"li\",\"bem\",\"form-checkbox\",\"yield\"]]",
    "moduleName": "forms/components/filters-multiselect/option.hbs",
    "isStrictMode": false
  });
  var _default = _exports.default = (0, _component.setComponentTemplate)(__COLOCATED_TEMPLATE__, (0, _templateOnly.default)());
});
define("@manager-web/grids/components/grid/card/empty", ["exports", "@ember/component", "@ember/component/template-only", "@ember/template-factory"], function (_exports, _component, _templateOnly, _templateFactory) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  const __COLOCATED_TEMPLATE__ = (0, _templateFactory.createTemplateFactory)(
  /*
    <div
    ...attributes
    class="flex items-center justify-center p-8 text-xl text-gray-500"
  >
    {{#if (has-block)}}
      {{yield}}
    {{else}}
      {{@title}}
    {{/if}}
  </div>
  */
  {
    "id": "XzWoQoCz",
    "block": "[[[11,0],[17,1],[24,0,\"flex items-center justify-center p-8 text-xl text-gray-500\"],[12],[1,\"\\n\"],[41,[48,[30,3]],[[[1,\"    \"],[18,3,null],[1,\"\\n\"]],[]],[[[1,\"    \"],[1,[30,2]],[1,\"\\n\"]],[]]],[13]],[\"&attrs\",\"@title\",\"&default\"],false,[\"div\",\"if\",\"has-block\",\"yield\"]]",
    "moduleName": "@manager-web/grids/components/grid/card/empty.hbs",
    "isStrictMode": false
  });
  var _default = _exports.default = (0, _component.setComponentTemplate)(__COLOCATED_TEMPLATE__, (0, _templateOnly.default)());
});
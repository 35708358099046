define("@manager-web/grids/components/grid/stat-row", ["exports", "@ember/component", "@ember/component/template-only", "@ember/template-factory"], function (_exports, _component, _templateOnly, _templateFactory) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  const __COLOCATED_TEMPLATE__ = (0, _templateFactory.createTemplateFactory)(
  /*
    <div
    class={{class-join
      "rounded-lg border"
      (or @theme "border-gray-200 bg-white")
    }}
    ...attributes
  >
    {{#if @isLoading}}
      <div class="w-full px-4 py-7">
        <LoadingString @width={{100}} @delay={{0}} />
      </div>
    {{else}}
      <div class="flex items-center gap-4 p-4">
        {{#if (has-block "icon")}}
          <div class="shrink text-5xl text-gray-300">
            {{yield to="icon"}}
          </div>
        {{/if}}
        <div class="grow">
          {{#if (has-block "tag")}}
            <div>
              {{yield to="tag"}}
            </div>
          {{/if}}
          <div class="text-xl">
            {{yield}}
          </div>
        </div>
        {{#if (has-block "action")}}
          <div>
            {{yield to="action"}}
          </div>
        {{/if}}
      </div>
    {{/if}}
  </div>
  */
  {
    "id": "D2FBfKfQ",
    "block": "[[[11,0],[16,0,[28,[37,1],[\"rounded-lg border\",[28,[37,2],[[30,1],\"border-gray-200 bg-white\"],null]],null]],[17,2],[12],[1,\"\\n\"],[41,[30,3],[[[1,\"    \"],[10,0],[14,0,\"w-full px-4 py-7\"],[12],[1,\"\\n      \"],[8,[39,4],null,[[\"@width\",\"@delay\"],[100,0]],null],[1,\"\\n    \"],[13],[1,\"\\n\"]],[]],[[[1,\"    \"],[10,0],[14,0,\"flex items-center gap-4 p-4\"],[12],[1,\"\\n\"],[41,[48,[30,4]],[[[1,\"        \"],[10,0],[14,0,\"shrink text-5xl text-gray-300\"],[12],[1,\"\\n          \"],[18,4,null],[1,\"\\n        \"],[13],[1,\"\\n\"]],[]],null],[1,\"      \"],[10,0],[14,0,\"grow\"],[12],[1,\"\\n\"],[41,[48,[30,5]],[[[1,\"          \"],[10,0],[12],[1,\"\\n            \"],[18,5,null],[1,\"\\n          \"],[13],[1,\"\\n\"]],[]],null],[1,\"        \"],[10,0],[14,0,\"text-xl\"],[12],[1,\"\\n          \"],[18,6,null],[1,\"\\n        \"],[13],[1,\"\\n      \"],[13],[1,\"\\n\"],[41,[48,[30,7]],[[[1,\"        \"],[10,0],[12],[1,\"\\n          \"],[18,7,null],[1,\"\\n        \"],[13],[1,\"\\n\"]],[]],null],[1,\"    \"],[13],[1,\"\\n\"]],[]]],[13]],[\"@theme\",\"&attrs\",\"@isLoading\",\"&icon\",\"&tag\",\"&default\",\"&action\"],false,[\"div\",\"class-join\",\"or\",\"if\",\"loading-string\",\"has-block\",\"yield\"]]",
    "moduleName": "@manager-web/grids/components/grid/stat-row.hbs",
    "isStrictMode": false
  });
  var _default = _exports.default = (0, _component.setComponentTemplate)(__COLOCATED_TEMPLATE__, (0, _templateOnly.default)());
});
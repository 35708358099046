define('@fortawesome/pro-duotone-svg-icons', ['exports'], (function (exports) { 'use strict';

  /*!
   * Font Awesome Pro 5.15.4 by @fontawesome - https://fontawesome.com
   * License - https://fontawesome.com/license (Commercial License)
   */
  var faExclamationTriangle = {
    prefix: 'fad',
    iconName: 'exclamation-triangle',
    icon: [576, 512, [], "f071", ["M569.52 440L329.58 24c-18.44-32-64.69-32-83.16 0L6.48 440c-18.42 31.94 4.64 72 41.57 72h479.89c36.87 0 60.06-40 41.58-72zM288 448a32 32 0 1 1 32-32 32 32 0 0 1-32 32zm38.24-238.41l-12.8 128A16 16 0 0 1 297.52 352h-19a16 16 0 0 1-15.92-14.41l-12.8-128A16 16 0 0 1 265.68 192h44.64a16 16 0 0 1 15.92 17.59z", "M310.32 192h-44.64a16 16 0 0 0-15.92 17.59l12.8 128A16 16 0 0 0 278.48 352h19a16 16 0 0 0 15.92-14.41l12.8-128A16 16 0 0 0 310.32 192zM288 384a32 32 0 1 0 32 32 32 32 0 0 0-32-32z"]]
  };
  var faHourglassHalf = {
    prefix: 'fad',
    iconName: 'hourglass-half',
    icon: [384, 512, [], "f252", ["M284.92 384H99.06A187.69 187.69 0 0 0 88 448h208a187.6 187.6 0 0 0-11.08-64zM192 208c40.83 0 75.86-33.2 92.92-80H99.08c17.06 46.8 52.1 80 92.92 80z", "M360 64a24 24 0 0 0 24-24V24a24 24 0 0 0-24-24H24A24 24 0 0 0 0 24v16a24 24 0 0 0 24 24c0 91 51 167.73 120.84 192C75 280.27 24 357 24 448a24 24 0 0 0-24 24v16a24 24 0 0 0 24 24h336a24 24 0 0 0 24-24v-16a24 24 0 0 0-24-24c0-91-51-167.73-120.84-192C309 231.73 360 155 360 64zm-64 384H88c0-77.46 46.2-144 104-144s104 66.52 104 144zM192 208c-57.79 0-104-66.52-104-144h208c0 77.46-46.2 144-104 144z"]]
  };
  var faSignalSlash = {
    prefix: 'fad',
    iconName: 'signal-slash',
    icon: [640, 512, [], "f695", ["M290.71 192.91A16 16 0 0 1 296 192h48a16 16 0 0 1 16 16v38.47zM88 384H40a16 16 0 0 0-16 16v96a16 16 0 0 0 16 16h48a16 16 0 0 0 16-16v-96a16 16 0 0 0-16-16zm400-38.6V112a16 16 0 0 0-16-16h-48a16 16 0 0 0-16 16v171.57zm128 98.93V16a16 16 0 0 0-16-16h-48a16 16 0 0 0-16 16v366.5zM408 496a16 16 0 0 0 16 16h48a15.72 15.72 0 0 0 13-7.15l-77-59.5zm-128 0a16 16 0 0 0 16 16h48a16 16 0 0 0 16-16v-87.75l-80-61.83zM168 288a16 16 0 0 0-16 16v192a16 16 0 0 0 16 16h48a16 16 0 0 0 16-16V309.32L204.41 288z", "M636.63 480.54L617 505.81a15.77 15.77 0 0 1-14.93 5.77 14.47 14.47 0 0 1-2.07.42h-2.56a15.42 15.42 0 0 0-2.9-3.37L6.18 53.9a16 16 0 0 1-2.81-22.45L23 6.18a16 16 0 0 1 22.47-2.81L633.82 458.1a16 16 0 0 1 2.81 22.44z"]]
  };
  var faTachometerAverage = {
    prefix: 'fad',
    iconName: 'tachometer-average',
    icon: [576, 512, [], "f629", ["M288 32C128.94 32 0 160.94 0 320a286.5 286.5 0 0 0 39.06 144.8c5.61 9.62 16.3 15.2 27.44 15.2h443c11.14 0 21.83-5.58 27.44-15.2A286.5 286.5 0 0 0 576 320c0-159.06-128.94-288-288-288zm55.12 384H232.88A63.36 63.36 0 0 1 264 324.75V128a24 24 0 0 1 48 0v196.75A63.36 63.36 0 0 1 343.12 416z", "M264 324.75V128a24 24 0 0 1 48 0v196.75A63.36 63.36 0 0 1 343.12 416H232.88A63.36 63.36 0 0 1 264 324.75z"]]
  };
  var faTachometerFastest = {
    prefix: 'fad',
    iconName: 'tachometer-fastest',
    icon: [576, 512, [], "f62b", ["M288 32C128.94 32 0 160.94 0 320a286.5 286.5 0 0 0 39.06 144.8c5.61 9.62 16.3 15.2 27.44 15.2h443c11.14 0 21.83-5.58 27.44-15.2A286.5 286.5 0 0 0 576 320c0-159.06-128.94-288-288-288zm196 343.67L350 398a66 66 0 0 1-6.9 18H232.88a63.33 63.33 0 0 1-8.88-32 63.85 63.85 0 0 1 118.37-33.39l133.68-22.28a24 24 0 0 1 7.9 47.34z", "M484 375.67L350 398a66 66 0 0 1-6.9 18H232.88a63.33 63.33 0 0 1-8.88-32 63.85 63.85 0 0 1 118.37-33.39l133.68-22.28a24 24 0 0 1 7.9 47.34z"]]
  };
  var faTachometerSlow = {
    prefix: 'fad',
    iconName: 'tachometer-slow',
    icon: [576, 512, [], "f62c", ["M288 32C128.94 32 0 160.94 0 320a286.5 286.5 0 0 0 39.06 144.8c5.61 9.62 16.3 15.2 27.44 15.2h443c11.14 0 21.83-5.58 27.44-15.2A286.5 286.5 0 0 0 576 320c0-159.06-128.94-288-288-288zm55.12 384H232.88a62.26 62.26 0 0 1 .47-64.86L124.8 206.41a24 24 0 0 1 38.41-28.81l108.56 144.74A63.5 63.5 0 0 1 343.12 416z", "M343.12 416H232.88a62.26 62.26 0 0 1 .47-64.86L124.8 206.41a24 24 0 0 1 38.41-28.81l108.56 144.74A63.5 63.5 0 0 1 343.12 416z"]]
  };
  var faTachometerSlowest = {
    prefix: 'fad',
    iconName: 'tachometer-slowest',
    icon: [576, 512, [], "f62d", ["M288 32C128.94 32 0 160.94 0 320a286.5 286.5 0 0 0 39.06 144.8c5.61 9.62 16.3 15.2 27.44 15.2h443c11.14 0 21.83-5.58 27.44-15.2A286.5 286.5 0 0 0 576 320c0-159.06-128.94-288-288-288zm55.12 384H232.88a65.78 65.78 0 0 1-6.9-18L92.05 375.67a24 24 0 0 1 7.9-47.34l133.68 22.28A63.77 63.77 0 0 1 343.12 416z", "M343.12 416H232.88a65.78 65.78 0 0 1-6.9-18L92.05 375.67a24 24 0 0 1 7.9-47.34l133.68 22.28A63.77 63.77 0 0 1 343.12 416z"]]
  };

  exports.faExclamationTriangle = faExclamationTriangle;
  exports.faHourglassHalf = faHourglassHalf;
  exports.faSignalSlash = faSignalSlash;
  exports.faTachometerAverage = faTachometerAverage;
  exports.faTachometerFastest = faTachometerFastest;
  exports.faTachometerSlow = faTachometerSlow;
  exports.faTachometerSlowest = faTachometerSlowest;

  Object.defineProperty(exports, '__esModule', { value: true });

}));

define("@manager-web/grids/components/grid/container", ["exports", "@ember/component", "@ember/component/template-only", "@ember/template-factory"], function (_exports, _component, _templateOnly, _templateFactory) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  const __COLOCATED_TEMPLATE__ = (0, _templateFactory.createTemplateFactory)(
  /*
    <div ...attributes class="bg-gray-100 p-4 last:grow md:p-8">
    <div class="mx-auto flex w-full max-w-3xl flex-col gap-4 last:grow md:gap-8">
      {{yield}}
    </div>
  </div>
  */
  {
    "id": "xonH8WU/",
    "block": "[[[11,0],[17,1],[24,0,\"bg-gray-100 p-4 last:grow md:p-8\"],[12],[1,\"\\n  \"],[10,0],[14,0,\"mx-auto flex w-full max-w-3xl flex-col gap-4 last:grow md:gap-8\"],[12],[1,\"\\n    \"],[18,2,null],[1,\"\\n  \"],[13],[1,\"\\n\"],[13]],[\"&attrs\",\"&default\"],false,[\"div\",\"yield\"]]",
    "moduleName": "@manager-web/grids/components/grid/container.hbs",
    "isStrictMode": false
  });
  var _default = _exports.default = (0, _component.setComponentTemplate)(__COLOCATED_TEMPLATE__, (0, _templateOnly.default)());
});